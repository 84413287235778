/**
 * Tables for both unclaimed and claimed alerts
 */
import React from 'react';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { Container } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslationProps } from 'react-i18next';

import { StoreState, setEntities, setBusy } from '../lib/redux';
import { Entity, Alert, Site } from '../lib/types';
import { formatUtc, makeDisplayId } from '../lib/utils';
import { WithApiProps, withApi } from '../providers';
import ExpandableAlerts, { Row } from './ExpandableAlerts';
import { commonStyles } from '../lib/styles';

// Fallback label for unknown Site ID
const UNKNOWN_SITE_ID = 'Unknown';

/** Comparator to sort Alerts oldest first */
const BY_AGE = (a: Alert, b: Alert) => a.createdTime - b.createdTime;

/** Sound file for new entities */
const SOUND_NEW_ENTITY = '/warningBeep.wav';
/** Sound file for additional alerts for an existing entity */
const SOUND_MORE_ALERTS = '/warningBeep.wav';
/** list of alert names that should be shown to PACE only after the alerts are active for 30secs. */
const DELAYED_ALERTS = ['vros-TL_TASK_LAYER_PAUSED_DUE_TO_OP_STATE', 'nrv-USER_STOP', 'nrv-SC_NOT_INITIALIZED',
    'nrv-SENSORS_NOT_INITIALIZED', 'nrv-TF_NOT_INITIALIZED', 'nexus-IDLE_WITH_ACTIVE_MISSION', 'nexus-ROBOT_WENT_OFFLINE'];

const styles = commonStyles;

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    entities: state.entities,
    sitesMap: state.sitesMap,
    limitedSites: state.limitedSites,
    prefs: state.prefs,
  }),
  // Dispatch
  {
    setEntities,
    setBusy
  }
)

type Props = WithStyles<typeof styles> & ConnectedProps<typeof connector> & WithApiProps & WithTranslationProps & {
  updateTitleWithUnclaimedAlertsCount: (count: number) => void;
};

class AlertTables extends React.Component<Props> {
  /** Data refresh interval */
  intervalId: number | undefined = undefined;

  /** New entity Audio element */
  sndNewEntity = new Audio(SOUND_NEW_ENTITY);
  /** More alerts Audio element */
  sndMoreAlerts = new Audio(SOUND_MORE_ALERTS);

  componentDidUpdate(prev: Props) {
    // Beep for new unclaimed entities
    this.beepForNewEntities(prev);
    const unclaimedTableRowCount = this.filterRows(this.prepareRows(this.props.entities), false).length;
    this.props.updateTitleWithUnclaimedAlertsCount(unclaimedTableRowCount);
  }

  render() {
    const { entities, sitesMap } = this.props;
    if (!entities) {
      return null;
    }

    // Prepare the table
    const rows = this.prepareRows(entities);

    // Split the rows
    const unclaimed = this.filterRows(rows, false);
    const claimed   = this.filterRows(rows, true);
    const suppressed = this.filterAllSuppressedRows(rows);

    return (
      <Container className={this.props.classes.container} maxWidth={false}>
        <ExpandableAlerts rows={unclaimed} claimed={false} sitesMap={sitesMap} />
        <ExpandableAlerts rows={suppressed} claimed={false} suppressed={true} sitesMap={sitesMap} />
        <ExpandableAlerts rows={claimed} claimed={true} sitesMap={sitesMap} />
      </Container>
    );
  }

  /**
   * Filter the rows based on the claimed/unclaimed flag
   *
   * @returns rows matching the claimed/unclaimed flag
   */
  filterRows = (rows: Row[], claimed: boolean): Row[] => {
    const filterAlertRows = claimed ? rows.filter(r => r.claimed_by) : rows.filter(r => !!r.claimed_by === claimed && !r.entity.alerts.every(r => r.suppressed));
    return filterAlertRows.map(res => {
      res.entity.alerts = res.entity.alerts.sort((a, b) => {
        if (a.suppressed === b.suppressed) {
          return 0;
        } else if (a.suppressed) {
          return 1;
        } else {
          return -1;
        }
      });
      return res;
    });
  }

  /**
   * Filter the rows based on the suppressed flag
   *
   * @returns rows matching the suppressed flag
   */
  filterAllSuppressedRows = (rows: Row[]): Row[] => {
    return rows.filter(row => {
      return row.entity.alerts.length > 0 && row.entity.alerts.every(alert => alert.suppressed) && !row.claimed_by;
    });
  }

  /**
   * Prepare the rows for the table
   *
   * @param entities Entities fetched from the API
   * @returns converted rows for the table.
   */
  prepareRows = (entities: Entity[]): Row[] => {
    const { limitedSites } = this.props;

    return entities.map(
      entity => {
        // Add the Site ID from Alert or Claim or Robot - at least until the API does this
        if (!entity.siteId) {
          entity.siteId = entity.alerts?.[0]?.siteId || entity.claim?.siteId || entity.robot?.siteId || UNKNOWN_SITE_ID;
        }
        return entity;
      }
    ).filter(
      // Focus on the selected sites with minimum alerts length of 1
      entity => ((!limitedSites || limitedSites.includes(entity.siteId)) && entity.alerts.length)
    ).map(entity => {
      // Show the first alert info in the main row
      const alert = this.prepareAlerts(entity);

      // Lookup up the site configuration
      const siteId = entity.siteId || UNKNOWN_SITE_ID;
      const site = this.props.sitesMap[siteId];
      const row: Row = {
        entity,
        site,
        siteId,
        siteName: site?.displayName || siteId,
        entity_id: makeDisplayId(entity, this.props.prefs),
        entity_type: entity.entityType,
        entity_name: entity.entityName,
        messages: entity.alerts?.map(a => a.message ?? '') ?? [],
        num_alerts: entity.alerts?.length ?? 0,
        created_at: formatUtc(alert?.createdTime),
        claimed_by: entity.claim?.claimedUser,
        claimed_at: formatUtc(entity.claim?.claimedTime),
        claimable: entity.alerts?.some(a => a.claimable ?? true),
      };

      if (site) {
        row.siteUrl = site.url;
        row.claim_url = this.buildClaimUrl(site, entity);
      }

      return row;
    });
  }

  /**
   * Sort the entity alerts and find the first alerts
   *
   * It is possible, though unlikely, that there are no alerts for an entity -
   * e.g. the last alert has been deleted, but the claim has not.
   *
   * @param entity an Entity
   * @returns the first alert
   */
  prepareAlerts = (entity: Entity): Alert | null => {
    // Filter alerts and return true if the alert is not a part of delayedAlerts and if the alert
    // is a part of delayed alerts and has been created for more than 30 secs, only then, return the delayed alert
    entity.alerts = entity.alerts.filter(alert => {
      if (DELAYED_ALERTS.includes(alert.messageKey)) {
        const alertCreatedTime = moment(alert.createdTime);
        const timeDiff = moment().diff(alertCreatedTime, 'seconds');
        return timeDiff > 30;
      } else {
        return true;
      }
    });

    // Translate the messages
    entity.alerts.forEach(a => a.message = this.t(a.messageKey, a.messageArgumentValues));

    // Sort the alerts by age, oldest first
    entity.alerts.sort(BY_AGE);

    // Use the oldest alert for the main row
    return entity.alerts.length > 0 ? entity.alerts[0] : null;
  }

  /**
   * Build a URL to a Vecna site that allows claiming the Entity
   *
   * @param row the row for the Entity being claimed
   * @returns URL to Vecna site that handles the claim
   */
  buildClaimUrl = (site: Site, entity: Entity) : string => {
    return `${site.url}?entityType=${encodeURIComponent(entity.entityType)}&entityId=${encodeURIComponent(entity.entityId)}`;
  }
  /**
   * Translate message.
   *
   * @param key message key
   * @param args message arguments
   * @returns translated message, or key if not found
   */
  t = (key: string, args: any[]): string  => {
    const { i18n } = this.props;
    return  i18n ? i18n.t(key, args) : key;
  }

  /**
   * Beep for any new unclaimed entities
   *
   * @param prev props before update
   */
  beepForNewEntities = (prev: Props) => {
    const { limitedSites, prefs } = this.props;

    if (!prefs.newEntityBeep) {
      // Shhhhhh...
      return;
    }

    // Filter for unclaimed entities on the focused sites
    const c_entities = this.props.entities.filter(
      // Only want the unclaimed IDs
      e => !e.claim
    ).filter(
      // Focus on the selected sites
      e => !limitedSites || limitedSites.includes(e.siteId)
    );

    // Get the prior alert counts
    const p_alerts = this.countAlerts(prev.entities);
    // Get the current unclaimed alert counts
    const c_alerts = this.countAlerts(c_entities);

    // Check if any of the new entities were not in the prior list
    const has_new = c_entities.some(e => !p_alerts[e.entityId]);
    if (has_new) {
      this.sndNewEntity.play();
    }

    // Check if any current entities have more alerts
    const has_more = c_entities.some(e => p_alerts[e.entityId] && c_alerts[e.entityId] > p_alerts[e.entityId]);
    if (has_more) {
      this.sndMoreAlerts.play();
    }
  }

  /**
   * Build a map of alerts counts by Entity ID
   *
   * @param entities list of Entities
   * @returns map of alert counts keyed by Entity ID
   */
  countAlerts = (entities: Entity[]): Record<string, number> => {
    return entities.reduce((counts, entity) => {
      counts[entity.entityId] = (entity.alerts?.length || 0);
      return counts;
    }, {} as Record<string, number>);
  }
}

export default connector(
  withTranslation()(
    withApi(withStyles(styles)(AlertTables))
  )
);
