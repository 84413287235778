/**
 * Tables with robot info for each customer site
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Card, CardContent, Container, createStyles, Theme } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslationProps } from 'react-i18next';

import { StoreState } from '../lib/redux';
import { Entity, SiteRobotInfo } from '../lib/types';
import { WithApiProps, withApi } from '../providers';
import RobotInfoTable from '../components/RobotInfoTable';
import { makeDisplayId } from '../lib/utils';

const styles = (theme: Theme) => createStyles({
  container: {
    padding: 0,
    height: '100%',
    // Force overflow handling into the children
    overflowY: 'scroll',
      '& > *': {
      // Put a space between the children
      marginBottom: theme.spacing(2),
      '&:last-child': {
        // But not at the very end
        marginBottom: 0
      }
    }
  },
});

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    robotEntities: state.entities,
    prefs: state.prefs,
    sites: state.sites,
    sitesMap: state.sitesMap,
    limitedSites: state.limitedSites,
  }),
)

type State = {
  rows: SiteRobotInfo[];
};

type Props = WithStyles<typeof styles> &
  ConnectedProps<typeof connector> &
  WithApiProps &
  WithTranslationProps & {};


class RobotProfile extends React.Component<Props> {
  state: State = {
    rows: [],
  }

  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    this.setState({rows: this.prepareRows(this.props.robotEntities as Entity[])});
  }

  componentDidUpdate(prev: Props) {
    if (prev.robotEntities !== this.props.robotEntities || this.props.limitedSites !== prev.limitedSites) {
      this.setState({rows: this.prepareRows(this.props.robotEntities as Entity[])});
    }
  }

  render() {
    //if data is not present, display meaningful message to user.
    if (!this.state.rows.length) {
      return (<Container className={this.props.classes.container} maxWidth={false}>
        <Card>
          <CardContent><span style={{fontSize: '20px', display: 'flex', justifyContent: 'center'}}>No Robots to Show</span></CardContent>
        </Card>
      </Container>);
    }

    // Prepare the table if data is present
    return (
      <Container className={this.props.classes.container} maxWidth={false}>
        {this.state.rows.map((res, index) => {
          return (<RobotInfoTable key={res.siteId + index} rows={res} />)
        })}
      </Container>
    );
  }

  /**
   * Prepares the rows for the robot info table by grouping entities by site.
   *
   * @param {Entity[]} entities The list of entities in the system
   * @returns {SiteRobotInfo[]} The rows for the table, grouped and sorted by site.
   */
  prepareRows = (entities: Entity[]): SiteRobotInfo[] => {
    const sites = this.props.sites;
    const siteGroups: SiteRobotInfo[] = sites.map(site => {
      return {
        siteName: site.displayName,
        siteUrl: site.url,
        siteId: site.siteId,
        robots: [],
      }
    })
    entities
      .filter(ent => ent.entityType === 'ROBOT')
      .map((entity: Entity) => {
        const existingGroup = siteGroups.find(group => group.siteId === entity.siteId);
        if (!entity.robot?.isRobotDisabled) {
          if (existingGroup) {
            const row = this.createRobotRow(entity);
            if (row) {
              existingGroup.robots.push(row);
              // Sort the robot info rows by serial number
              existingGroup.robots.sort((a, b) => a.entityId.localeCompare(b.entityId));
            }
          }
        }
      });
    return this.filterAndSortGroups(siteGroups);
  }

  /**
   * Creates a row object for a given robot entity which contains robot-specific details
   * like entity ID, name, number of alerts, and the user who claimed the robot.
   *
   * @param {Entity} entity - The robot entity to create a row for.
   * @returns The row object containing robot details, or null if the entity is invalid.
   */
  createRobotRow = (entity: Entity) => {
    if (!entity.robot) { //Return if the robot data doesn't exist for this site.
      return null;
    }
    return {
      ...entity.robot,
      entityId: makeDisplayId(entity, this.props.prefs),
      entityName: entity.entityName,
      numAlert: entity.alerts?.length ?? 0,
      claimedBy: entity.claim?.claimedUser,
    };
  }

  /**
   * Filters and sorts the grouped robot data by site.
   *
   * Filters out sites that are not in the list of allowed sites, then sorts the remaining
   * groups by site name in ascending order.
   *
   * @param {SiteRobotInfo[]} siteToRobotMap - The mapped list of robot groups by site.
   * @returns {SiteRobotInfo[]} The filtered and sorted list of robot groups.
   */
  filterAndSortGroups = (siteToRobotMap: SiteRobotInfo[]): SiteRobotInfo[] => {
    const filteredSites = this.props.limitedSites && this.props.limitedSites.length ? this.props.limitedSites : this.props.sites.map(site => site.siteId);
    return siteToRobotMap
      .filter(grp => filteredSites.includes(grp.siteId))
      .sort((a, b) => a.siteName.localeCompare(b.siteName));
  }
}

export default connector(
  withTranslation()(
    withApi(withStyles(styles)(RobotProfile))
  )
);