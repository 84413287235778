/**
 * Interface to the REST API
 */
import API from '@aws-amplify/api';

import { Store } from 'redux';

import type { Entity, Site } from '../lib/types';
import { addError, StoreState } from '../lib/redux';

// Shorthand for encoding variables in URLs
// const U = encodeURIComponent

/**
 *  A response from the API.
 *
 * All data wil be wrapped in the 'data' field
 */
type ApiResponse<T> = {
  data: T;
  error?: string;
}

/** Amplify API request method */
type ApiMethod = (apiName: any, path: any, init: any) => Promise<any>;

/**
 * Interface to the REST API
 */
export default class RestAPI {
  constructor(private store: Store<StoreState>) {}

  /**
   * Pick an Amplify API method
   *
   * @param method HTTP method name
   * @returns Amplify API callback
   */
  find_method = (method: string): ApiMethod => {
    switch (method) {
      case 'GET'   : return API.get;
      case 'DELETE': return API.del;
      case 'PATCH' : return API.patch;
      case 'POST'  : return API.post;
      case 'PUT'   : return API.put;
      default:
        throw new Error(`Unknown API method '${method}`);
    }
  }

  /**
   * Core API request, now with built-in error handling!
   *
   * HTTP and API errors are dispatched through redux.
   *
   * The response is null in the case of an error.
   *
   * Note: You'll need to call API.* directly if you want handle the errors yourself.
   *
   * @param method HTTP method (GET, DELETE, PATCH, POST, PUT)
   * @param apiName Name of Amplify API
   * @param path Endpoint path
   * @param extra Extra data to pass in request
   * @returns async API response, or null if there was an error
   */
  api_request = async <T>(method: string, apiName: string, path: string, extra: any = null): Promise<T|null> => {
    try {
      // Figure out which API method to call
      const api_method = this.find_method(method);

      // Make the call
      const resp = (await api_method.call(API, apiName, path, extra) as ApiResponse<T>);

      // The actual value is wrapped in the 'data' field
      if (resp.data) {
        return resp.data;
      }

      // TODO: check for error text?
      return null;
    }
    catch (err: any) {
      // Log the error
      console.log(`API ERROR:`, method, apiName, path, extra, err?.response);

      // Present the error to the user
      this.store.dispatch(addError(err));

      // Nothing to return
      return null;
    }
  }

  /**
   * Get all the current entities
   */
  getEntities = () => {
    return this.api_request<Entity[]>('GET', 'data', '/api/entities');
  }

  /**
   * Get all the known sites
   */
  getSites = async () => {
    return this.api_request<Site[]>('GET', 'data', '/api/sites');
  }
}
