/**
 * A Sidebar menu component that displays a list of menu items in the application.
 */

import React from "react";
import { Link } from "react-router-dom";
import withRouter, { InjectedProps } from "./withRouter";
import { Theme, createStyles, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/styles";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

export interface MenuItem {
  label: string;
  icon: JSX.Element;
  link: string;
  appTitle?: string;
}

type SidebarMenuProps ={
  menuItems: MenuItem[];
  itemClicked: (item: MenuItem) => void;
  location?: ReturnType<typeof withRouter>;
  navigate?: ReturnType<typeof withRouter>;
} & WithStyles<typeof styles>

interface SidebarMenuState {
  collapsed: boolean; //Indicates if the sidebar is collapsed or open
}

const styles = (theme: Theme) => createStyles({
  sidebar: {
    fontFamily: theme.typography.fontFamily,
    width: "250px",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    transition: "width 0.3s ease",
    overflowY: "auto",
    marginRight: theme.spacing(3),
  },
  sidebarCollapsed: {
    width: "80px",
    textAlign: "center"
  },
  sidebarToggle: {
    cursor: "pointer",
    textAlign: "right",
    padding: theme.spacing(1.2),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.background.default}`,
    display: "flex",
    justifyContent: "end",
  },
  sidebarToggleCollapsed: {
    textAlign: "center",
    justifyContent: "center",
  },
  sidebarToggleIcon: {
    width: "35px",
    borderRadius: "50%",
    background: "black",
    padding: theme.spacing(0.6),
    display: "flex"
  },
  collapseIcon: {
    fill: "#000",
  },
  sidebarMenu: {
    listStyleType: "none",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    "& :active" : {
      backgroundColor: theme.palette.background.default,
    }
  },
  sidebarMenuLink: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    padding: theme.spacing(1.2),
    textDecoration: "none",
    transition: "background-color 0.3s ease",
    position: "relative",
    "& :hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  sidebarMenuLabel: {
    marginLeft: theme.spacing(1.2),
  },
  sidebarMenuIcon: {
    width: "20px",
    display: "inline-block",
    textAlign: "center",
  },
  sidebarMenuItemActive: {
    backgroundColor: theme.palette.background.default,
  }
})

class SidebarMenu extends React.Component<SidebarMenuProps & InjectedProps, SidebarMenuState> {
  constructor(props: SidebarMenuProps & InjectedProps) {
    super(props);
    const activeItem = this.props.menuItems.filter(r => r.link === this.props.location.pathname);
    if (activeItem.length) {
      this.props.itemClicked(activeItem[0]);
    }
    this.state = {
      collapsed: true,
    };
  }

  isItemActive = (link: string) => {
    return this.props.location.pathname === link;
  };

  onSidebarToggled = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed
    }));
  };

  onMenuItemClick = (menuItem: MenuItem) => {
    this.props.navigate(menuItem.link);
    this.props.itemClicked(menuItem);
  }

  render() {
    const { menuItems, classes } = this.props;
    const { collapsed } = this.state;
    return (
      <div className={`${classes.sidebar} ${collapsed ? classes.sidebarCollapsed: ""}`}>
        <div className={`${classes.sidebarToggle} ${collapsed ? classes.sidebarToggleCollapsed: ""}`} onClick={this.onSidebarToggled}>
          <span className={`${classes.sidebarToggleIcon}`}>
              {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </span>
        </div>
        {!collapsed && <div className="sidebar-content">
          <ul className={classes.sidebarMenu}>
          {menuItems.length && menuItems.map((menuItem: MenuItem) => {
            return (<li
              className={` ${
                this.isItemActive(menuItem.link) ? classes.sidebarMenuItemActive : ""
              }`} onClick={this.onMenuItemClick.bind(this, menuItem)}
            >
              <Link to={menuItem.link} className={classes.sidebarMenuLink}>
                <span className={classes.sidebarMenuIcon}>{menuItem.icon}</span>
                <span className={classes.sidebarMenuLabel}>{menuItem.label}</span>
              </Link>
            </li>)
          })}
          </ul>
        </div>}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(SidebarMenu));