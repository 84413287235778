
/**
 * Redux middleware to process Websocket events
 */
import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux';

import Auth from '@aws-amplify/auth';
import reduxWebsocket,  { send } from '@giantmachines/redux-websocket';

import { StoreState, updateEntity, deleteEntity } from './redux';

/**
 * Handle a WebSocket message
 *
 * @param store Redux store
 * @param message WebSocket message
 */
function handle_message(store: MiddlewareAPI<Dispatch<AnyAction>, StoreState>, message: any) {
  switch (message.type) {
    case 'update':
      if (!message.data) {
        console.error(`websocket: 'update' message missing data`, message);
        return;
      }
      // Update a single entity
      console.log(`websocket: update '${message.data.entityId}'`, message.data);
      store.dispatch(updateEntity(message.data));
      break;

    case 'delete':
      if (!message.data) {
        console.error(`websocket: 'delete' message missing data`, message);
        return;
      }
      // Delete a single entity
      console.log(`websocket: delete '${message.data.entityId}'`, message);
      store.dispatch(deleteEntity(message.data.entityId));
      break;

    default:
      console.error(`websocket: unknown message type '${message.type}'`, message);
      break;
  }
}

/**
 * Authenticate the WebSocket connection
 */
const authenticate = async (store: MiddlewareAPI<Dispatch<AnyAction>, StoreState>) => {
  console.log('websocket: authenticate');

  // We need the current JWT token for authentication
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  // Send the token the server to authenticate the connection
  store.dispatch(send({
    type: 'authenticate',
    payload: { token }
  }));
}

/**
 * Process Websocket actions as Redux middleware
 *
 * This needs to be added to the Redux store middleware after the WebSocket so
 * it can handle the WebSocket messages.
 */
const messageListener: Middleware<{}, StoreState> = store => next => action => {
  if (action.type === 'REDUX_WEBSOCKET::MESSAGE') {
    // Handle a message
    handle_message(store, JSON.parse(action.payload.message));
  }
  else if (action.type === 'REDUX_WEBSOCKET::OPEN') {
    // Authenticate after opening the connection
    authenticate(store);
  }
  else if (action.type.startsWith('REDUX_WEBSOCKET::')) {
    // Log everything else websocket-related
    console.log(`websocketListener: ${action.type}`, action);
  }


  // Pass the action along to the next middleware
  return next(action);
}

/**
 * Assembles the list of Redux middleware related to WebSockets.
 *
 * Note: the returned list should be expanded for applyMiddleware()
 *
 * For example:
 *   const store = createStore(
 *     reduxReducer,
 *     composeEnhancers(applyMiddleware(...websocketReduxMiddleWare(), thunk)),
 *   );
 *
 * @returns list of Redux middleware
 */
export const websocketReduxMiddleware = () => {
  return [
    // Core WebSocket
    reduxWebsocket({
      // Reconnect if there was network error
      reconnectOnError: true,
      // Reconnect even if the server closes the connection
      reconnectOnClose: true,
    }),
    // Act on messages from the WebSocket
    messageListener,
  ];
}

export default websocketReduxMiddleware;
