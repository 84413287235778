/**
 * A higher-order component (HOC) that injects props from 'react-router-dom'
 * into the wrapped component.
 */

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Type of Props for the wrapped component
export type InjectedProps = {
  location: ReturnType<typeof useLocation>;
  navigate: ReturnType<typeof useNavigate>;
}

// A function that takes a component and returns a new component
function withRouter<P extends InjectedProps>(
  WrappedComponent: React.ComponentType<P>
) {
  // Returns a new component that renders the WrappedComponent with router props
  return (props: any) => {
    // Use hooks to get router props
    const location = useLocation();
    const navigate = useNavigate();

    // Returns the WrappedComponent with injected router props
    return (
      <WrappedComponent
        {...props as P} // Pass through any original props
        location={location} // Inject location prop
        navigate={navigate} // Inject navigate prop
      />
    );
  };
}

export default withRouter;