/**
 * Defines common styles for components, including container layout,
 * spacing, and table toolbar positioning.
 */

import { Theme, createStyles } from "@material-ui/core";

export const commonStyles = (theme: Theme) => createStyles({
  container: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // Force overflow handling into the children
    overflow: "hidden",
    "& > *": {
      // Put a space between the children
      marginBottom: theme.spacing(2),
      "&:last-child": {
        // But not at the very end
        marginBottom: 0,
      },
    },
  },
  // Pin the table toolbar to the top of the container
  "& .MuiToolbar-root": {
    position: "sticky",
    top: "0px",
    // Force the background to avoid overlap
    backgroundColor: theme.palette.background.paper,
    // Keep above the other rows, especially the column headers
    zIndex: 1000,
  },
  noprint: {
    "@media print": {
      display: "none",
    },
  },
  title: {
    // Indicate that the title can be clicked on
    cursor: 'pointer',
  },
  table: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    // Be small by default
    flex: '0 0 0%',
    // Animate the opening/closing
    transition: 'flex .5s',
    // When open, take up all available space and allow scrolling
    '& [class^="MUIDataTable-responsiveBase"]': {
      overflow: 'auto'
    },
    '& .online': {
      fill: "green"
    },
    '& .offline': {
      fill: "red"
    },
    '&.open': {
      flex: '1 1 100%',
      overflowX: 'auto',
    },
  },
});
